import useWebSocket from 'react-use-websocket';
import DeviceFingerprinting from 'device-fingerprinting';

type Command7Data = {
  finishTime: string;
  level: string;
  name: string;
  usdt: string;
  userName: string;
  userHeadUrl: string;
  command: 7;
  category: number;
};

type Command8Data = {
  finishTime: string;
  cycleIndex: string;
  percent: string;
  command: 8;
  category: number;
};

export interface WSHandlers {
  7?: (data: Command7Data) => void;
  8?: (data: Command8Data) => void;
  [key: number]: (data: any) => void;  
};

export const useGameWebSocket = (token: string, handlers: WSHandlers) => {
  const { sendJsonMessage } = useWebSocket(process.env.NEXT_PUBLIC_WEB_SOCKET_URL, {
    onOpen: () => {
      console.log('WebSocket connected');
      sendJsonMessage({
        "app": 1,
        "token": token,
        "deviceUid": DeviceFingerprinting().getDeviceFingerprint(),
        "command": 1,
      }, false);
    },
    onMessage: (message) => {
      if (message.data === 'pong') return;
      
      try {
        const { data } = JSON.parse(message.data);
        if (!data || !data.command) return;
        
        const handler = handlers[data.command];
        if (handler) {
          handler(data);
        }
      } catch(err) {
        console.error('WebSocket message parsing error:', err);
      }
    },
    onError: (event) => {
      console.error('WebSocket error:', event);
    },
    heartbeat: {
      message: "ping",
      returnMessage: "pong",
      timeout: 60 * 1000,
      interval: 30 * 1000,
    }
  });

  return { sendJsonMessage };
}; 