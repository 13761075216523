import { COOKIE_KEY_USER_TOKEN } from '@/constants/cookieStorageKeys';
import { LOCAL_KEY_USER_TOKEN } from '@/constants/localStorageKeys';
import { cookie } from '@/utils/cookies';
import { checkRuntime } from '@/utils/checkRuntime';
import { getLocalStorage } from '@/utils/localStoreHelper';
import { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import type { Interceptor } from './types';

const absoluteNoAuthUrlList = [];
// const methodGetNeedAuthUrlList = [''];

const autoAddAuthHeaderByDefault: Interceptor<InternalAxiosRequestConfig> = [
  (config: InternalAxiosRequestConfig) => {
    if (!config.headers) {
      config['headers'] = {} as AxiosRequestHeaders;
    }

    const userToken =
      cookie.get(COOKIE_KEY_USER_TOKEN) ||
      getLocalStorage(LOCAL_KEY_USER_TOKEN);
    const canAddHeader =
      !checkRuntime &&
      userToken &&
      !absoluteNoAuthUrlList.some((item) => config.url?.includes(item)) &&
      !config.headers['Authorization'];
    if (config.method !== 'get' && canAddHeader) {
      config.headers['Authorization'] = `${userToken}`;
    }

    return config;
  },
];

export default autoAddAuthHeaderByDefault;
