import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogAction,
    AlertDialogTitle,
    AlertDialogCancel,
} from '@/components/ui/alert-dialog';
import Image from 'next/image';
import { getPublicAsset } from '@/utils/getPublicAsset';
type DialogProps = {
    show: boolean;
    onClose?: () => void;
};

const ReduceMiningDialog: React.FC<DialogProps> = ({
    show,
    onClose
}) => {
    return <AlertDialog open={show}>
        <AlertDialogContent
            className="bg-[#FDF4DC] font-comicbd text-white  p-0"
            enable3DBorder
            shadowColor="#E49F4F"
        >
            <AlertDialogTitle className="text-center bg-[#EFC453] py-1 stroke-black">
                Mining Decay Rate
            </AlertDialogTitle>
            {/* Content */}
            <div className='text-[#3B392D] text-[1.2rem] px-4'>
                <div>This data represents the decay rate of mining coins</div>
                <Image src={getPublicAsset('/images/lobby/chart.png')} alt='banana' width={1100} height={500} />
            </div>
            <AlertDialogFooter className=" my-2">
                <AlertDialogCancel
                    onClick={onClose}
                >
                    Close
                </AlertDialogCancel>
                <AlertDialogAction
                    onClick={() => window.open('https://paper.pinamania.com', '_blank')}
                >
                    View Whitepaper
                </AlertDialogAction>
            </AlertDialogFooter>
        </AlertDialogContent>
    </AlertDialog>;
};

export default ReduceMiningDialog;
