import request from '@/http/request';
import { UserGameStatusDTO } from './login';
import { useQuery } from '@tanstack/react-query';

export enum EventId {
  spin = 1, // start spin
  start = 2, // game start
  click = 3, // click coin
  clean3 = 4, // clean3
  buy = 5,
  use = 6,
  reward = 7,
  AD = 11,
  share = 12,
  sell_pina = 13,
  withdraw = 14,
  withdraw_approve = 15,
  withdraw_reject = 16,
}

export enum GameItemId {
  cells = 1,
  undo = 2,
  hint = 3,
  shuffle = 4,
  revive = 5,
  spin = 6,
  ticket = 7,
  skip = 9,
}

export interface UserMetaModel {
  userEventDTO: UserEventDTO;
  userModel: UserModel;
  userGameStatusDTO: UserGameStatusDTO;
  gameItemDTOList: GameItemDTOList[];
  userThirdPartyDTOList: any[];
  easterEggDataDTO?: EasterEggDataDTO;
}

export interface GameItemDTOList {
  gameItemId: GameItemId;
  gameItemName: string;
  gameItemType: string;
  gameItemPrice: string;
  haveCount: string;
}

export interface Banana {
  id: string;
  name: string;
  logo: string;
  level: string;
  desc: string;
  theme: string;
  sellGold: string;
  sellAmount: string;
  sentCount: string;
  rarity: string;
  createdTime: string;
}

export interface UserEventDTO {
  id: string;
  tgId: string;
  amountGold: string;
  payoutGold: string;
  banana?: Banana | undefined;
  gameItemId: GameItemId;
}

export interface UserModel {
  tgId: string;
  name: string;
  headerUrl?: string;
  registerIp: string;
  registerCountry: string;
  registerCity: string;
  registerTime: string;
  gold: string;
  bananaCount: string;
  depositAmount: string;
  bananaOnlineId: string;
  claimEasterEgg: boolean;
  skipToday: boolean;
  skipNum: number;
}

export interface UserEventDTO {
  id: string;
  tgId: string;
  eventId: EventId;
  amountGold: string;
  bananaId: string;
  payoutGold: string;
  gameItemId: GameItemId;
}

export interface EasterEggDataDTO {
  tgId: string;
  pass: string; //用户手动输入密码 可获得彩蛋奖励
  expireTime: string; //过期时间，24h以后
  users: [];
  link: string;
  myBananaId: string;
  myGold: string;
}

export const gameStart = (data: { eventId: EventId, potionId?: string }) => {
  return request<UserMetaModel>({
    url: '/api/mini-api/game/start',
    data,
    method: 'post',
    suppressWarning: false,
  }).then((res) => res.data);
};
interface ComboCounts {
  [key: string]: number;
}
export const fetchGameSettle = (data: { clear: number; all: number, combo: ComboCounts }) => {
  return request<UserMetaModel>({
    url: '/api/mini-api/game/settle',
    data,
    method: 'post',
    suppressWarning: false,
  }).then((res) => res.data);
};

export const fetchConsumablesItem = (data: { gameItemId: GameItemId }) => {
  return request<{
    leftCount: string;
  }>({
    url: '/api/mini-api/game/consumables-item',
    data,
    method: 'post',
    suppressWarning: false,
  }).then((res) => res.data);
};

export const fetchGetGameItem = (data: {
  eventId: EventId.AD | EventId.share | EventId.buy;
  gameItemId: GameItemId;
}) => {
  return request<{
    leftCount: string;
  }>({
    url: '/api/mini-api/game/get-game-item',
    data,
    method: 'post',
    suppressWarning: false,
  }).then((res) => res.data);
};

export const fetchGetAdsCount = () => {
  return request({
    url: '/api/mini-api/game/allow-watch-ad',
    method: 'post',
    suppressWarning: false,
  }).then((res) => res.data);
};

export interface GameItemPackage {
  id: string;
  name: string;
  desc: string;
  picUrl: string;
  invoiceLink: string;
  price: number;
  enable: boolean;
  cellsCount: number;
  undoCount: number;
  hintCount: number;
  shuffleCount: number;
  reviveCount: number;
  spinCount: number;
}

export const fetchGetGameItemPackages = () => {
  return request<{
    list: GameItemPackage[];
  }>({
    url: '/api/mini-api/game/game-item-packages',
    method: 'post',
    suppressWarning: false,
  }).then((res) => res.data);
};

export const useGameItemPackages = () => {
  return useQuery({
    queryKey: ['gameItemPackages'],
    queryFn: () => fetchGetGameItemPackages(),
    staleTime: 0,
  });
};
