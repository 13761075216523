/**
 * businese-related constants
 */
export const BONUS_PACK_CONSTANTS = {
  DEFAULT_WAGER: 35,
  DEFAULT_EXPIRE_DAYS: 60,
};

export const URLS = {
  domain: [
    'www.pinamania.com',
    'pinamania.com',
    'staging.pinamania.com',
    'www.staging.pinamania.com',
    'beta.pinamania.com',
    'www.beta.pinamania.com',
  ],
  telegramDomain: [
    'tg.beta.pinamania.com',
    'www.tg.beta.pinamania.com',
    'tg.staging.pinamania.com',
    'www.tg.staging.pinamania.com',
    'tg.pinamania.com',
    'www.tg.pinamania.com',
    'www.beta.tg.pinamania.com',
  ],
  mainDomain: 'pinamania.com',
  lobbyURL: (origin: string) => origin + '/casino',
  depositURL: (currentUrl: string) => currentUrl + '?wallet=deposit',
  localTgURL: [
    'https://tg-local-pinnamania.ngrok.dev',
    'pinnamania.ngrok.dev',
    'https://luki.ngrok.io',
    'https://hardy-precise-weasel.ngrok-free.app',
    process.env.NEXT_PUBLIC_DEV_DOMAIN || 'localhost',
    '0.0.0.0',
    'localhost',
    '127.0.0.1',
  ],
};
