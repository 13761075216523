import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { initUtils } from '@telegram-apps/sdk';
import { EventId } from '@/apis/game';
import { fetchEasterEggBonus, useEasterEggInfo } from '@/apis/easterEgg';
import { useBagList } from '@/apis/bag';
import { useToast } from '@/components/ui/use-toast';
import Image from 'next/image';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { cn } from '@/lib/utils';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import { useClipboard } from '@/hooks/useClipboard';
import useBizSound from '@/hooks/useBizSound';
import SocialIcon from '@/components/ui/SocialIcon';
import { Button } from '@/components/ui/button';
import SizedConfetti from '@/components/ui/ConfettiAnimations/SizedConfetti';
import { arabicToRoman } from '@/utils/number/romanNumerals';
import CountUp from 'react-countup';
import { userEasterEggInfoAtom, userInfoAtom } from '@/store/userInfo';
import useUserStatus from '@/hooks/useUserStatus';
import {
  isEasterEggFirstTimeAtom,
  isCopiedEasterEggPassAtom,
} from '@/store/runtime';
import { useRouter, useParams, useSearchParams } from 'next/navigation';
import { getEasterEggCode } from '@/helper/getCode';
import addComma from '@/utils/number/addComma';
import { getEasterEggShareText, getTelegramMiniAppUrl } from '@/utils/shareLinks';
import { useLocalStorage } from "@uidotdev/usehooks";
import { LOCAL_KEY_EASTER_EGG_SHARE_COUNT } from "@/constants/localStorageKeys";


export const BonusDisplay = () => {
  const [easterEggInfo] = useAtom(userEasterEggInfoAtom);
  const { data: bagList } = useBagList();
  const bananaMap = useMemo(() => {
    const m = new Map();
    bagList?.data?.bananaRecordList?.forEach((banana) => {
      m.set(banana.id, banana);
    });
    return m;
  }, [bagList]);
  const pina = useMemo(() => {
    return bananaMap?.get(easterEggInfo?.myBananaId);
  }, [easterEggInfo, bananaMap]);

  if (!pina) return null;

  return (
    <div className="flex flex-col w-full flex-start space-y-3 text-black">
      <div className="relative flex w-full rounded-[8px] bg-[#ffeefb] border-[2px] border-black items-center p-1">
        <div className="absolute py-0.5 px-1 max-w-full text-nowrap truncate z-30 top-[-0.25rem] left-1/2 -translate-x-1/2 -translate-y-1/2 text-xs text-[#ffd580] stroke-1 stroke-black bg-easter-egg border-2 border-black rounded-full">
          {pina?.theme}
        </div>
        <div className={'relative w-[48px] h-[48px] rounded-[6px]'}>
          <Image
            className="absolute w-[28px] h-[28px] right-[-4px] top-[-4px] z-20"
            width={38}
            height={38}
            src={getPublicAsset('/images/easter-egg/lv.png')}
            alt="lv"
          />
          <span className="flex absolute text-xs w-[28px] h-[28px] right-[-4px] top-[-4px] justify-center items-center z-30">
            {arabicToRoman(pina?.level)}
          </span>
          <Image
            width={40}
            height={40}
            src={getPublicAsset('/images/pina/PinaManiaPlaceholder.png')}
            className={
              'absolute rounded-[6px] left-[1px] top-[1px] w-[46px] h-[46px] z-20'
            }
            alt="pina"
          />
          <Image
            width={91}
            height={91}
            src={getPublicAsset('/images/easter-egg/pina-bg.png')}
            className={
              'absolute rounded-[6px] top-0 left-0 w-[48px] h-[48px] z-10'
            }
            alt="pina"
          />
        </div>
        <div className="ml-2">
          <span className="text-left font-bold text-lg">{pina?.name} Pina</span>
          <p className={'text-left'}>
            <span className={'inline-flex justify-center items-center'}>
              <Image
                className="mr-1 w-[14px] h-[14px]"
                width={14}
                height={14}
                src={getPublicAsset('/images/bag/usdt.png')}
                alt="usdt"
                priority
              />
              <CountUp
                className={'text-sm'}
                preserveValue={true}
                useGrouping={true}
                separator=","
                end={Number(pina?.amount)}
                decimals={2}
              />
            </span>
            <span className={'ml-2 inline-flex justify-center items-center'}>
              <Image
                className="mr-1 w-[14px] h-[14px]"
                width={14}
                height={14}
                src={getPublicAsset('/images/lobby/coins.png')}
                alt="icon"
              />
              <CountUp
                className={'text-sm'}
                preserveValue={true}
                useGrouping={true}
                separator=","
                end={Number(pina?.gold)}
              />
            </span>
          </p>
        </div>
      </div>
      <div className="relative flex w-full rounded-[8px] bg-[#ffeefb] border-[2px] border-black items-center p-1">
        <div
          className={
            'flex justify-center items-center relative bg-[#e9c864] w-[48px] h-[48px] border-[2px] border-black rounded-[6px]'
          }
        >
          <Image
            className="w-[24px] h-[24px]"
            width={40}
            height={40}
            src={getPublicAsset('/images/lobby/coins.png')}
            alt="icon"
          />
        </div>
        <div className="ml-2">
          <span className="text-left font-bold text-lg">Pina Coins</span>
          <div className="flex flex-row justify-start items-center">
            <div className="mr-1">Reward</div>
            <CountUp
              preserveValue={true}
              useGrouping={true}
              separator=","
              end={Number(easterEggInfo?.myGold)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
