import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { useAtom } from 'jotai';
import { initUtils } from '@telegram-apps/sdk';
import { EventId } from '@/apis/game';
import { fetchEasterEggBonus, useEasterEggInfo } from '@/apis/easterEgg';
import { useBagList } from '@/apis/bag';
import { useToast } from '@/components/ui/use-toast';
import Image from 'next/image';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { cn } from '@/lib/utils';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import { useClipboard } from '@/hooks/useClipboard';
import useBizSound from '@/hooks/useBizSound';
import SocialIcon from '@/components/ui/SocialIcon';
import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@/components/ui/popover';
import SizedConfetti from '@/components/ui/ConfettiAnimations/SizedConfetti';
import { arabicToRoman } from '@/utils/number/romanNumerals';
import CountUp from 'react-countup';
import { userEasterEggInfoAtom, userInfoAtom } from '@/store/userInfo';
import useUserStatus from '@/hooks/useUserStatus';
import {
  isEasterEggFirstTimeAtom,
  isCopiedEasterEggPassAtom,
} from '@/store/runtime';
import { useRouter, useParams, useSearchParams } from 'next/navigation';
import { getEasterEggCode } from '@/helper/getCode';
import addComma from '@/utils/number/addComma';
import {
  getEasterEggShareText,
  getTelegramMiniAppUrl,
} from '@/utils/shareLinks';
import { useLocalStorage } from '@uidotdev/usehooks';
import { LOCAL_KEY_EASTER_EGG_SHARE_COUNT } from '@/constants/localStorageKeys';
import { BonusDisplay } from './BonusDisplay';
import { EasterEggShare } from './EasterEggShare';
import { InputSection } from './InputSection';

export const EasterEggContent = ({
  status,
  type,
  code,
  easterEggInfo,
  bouns,
  err,
  handleCodeChange,
  eggBonusStatus,
  isClaiming,
  isRedirecting,
  handleClaim,
  handleViewReward,
  handlePlayGame,
}) => {
  console.log('status', status);
  const renderContent = () => {
    switch (status) {
      case 'own_claimed':
        return (
          <OwnClaimedContent
            bouns={bouns}
            handleViewReward={handleViewReward}
            handlePlayGame={handlePlayGame}
            isRedirecting={isRedirecting}
            easterEggInfo={easterEggInfo}
          />
        );
      case 'already_claimed':
        return (
          <AlreadyClaimedContent
            easterEggInfo={easterEggInfo}
            handlePlayGame={handlePlayGame}
            handleViewReward={handleViewReward}
            isRedirecting={isRedirecting}
          />
        );
      case 'failed_claimed':
        return (
          <FailedContent
            code={code}
            err={err}
            handleCodeChange={handleCodeChange}
            isClaiming={isClaiming}
            handleClaim={handleClaim}
          />
        );
      default:
        return (
          <DefaultContent
            eggInfo={easterEggInfo}
            type={type}
            code={code}
            err={err}
            isClaiming={isClaiming}
            isRedirecting={isRedirecting}
            handleCodeChange={handleCodeChange}
            handleClaim={handleClaim}
            handleViewReward={handleViewReward}
          />
        );
    }
  };

  return (
    <div className="flex flex-col mt-8 p-4 text-[#9339bf] justify-center items-center font-comicbd">
      {renderContent()}
    </div>
  );
};

const OwnClaimedContent = ({
  bouns,
  easterEggInfo,
  handleViewReward,
  handlePlayGame,
  isRedirecting,
}) => {
  if (!easterEggInfo) {
    return null;
  }

  return (
    <>
      <div className="w-full mb-4 font-bold text-lg text-center">
        Congrats, You’ve Claimed!
      </div>
      <BonusDisplay />
      {easterEggInfo?.pass ? (
        <>
          <EasterEggShare />
          <Button
            onClick={handleViewReward}
            className={cn(
              'flex justify-center items-center px-[20px] h-[40px] flex-shrink-0 bg-[#F6C334] text-lg mt-4',
            )}
            shadowColor={'#a37800'}
          >
            <span className="text-black">
              {isRedirecting ? 'Loading...' : 'My Rewards'}
            </span>
          </Button>
        </>
      ) : (
        <>
          <div className="mt-4 bg-[#fceced] rounded-[6px] text-sm text-black text-center p-2 border-2 border-black">
            Complete your first expedition to reveal the cipher, share it, and
            earn Pina Coins per claim!
          </div>
          <Button
            onClick={handlePlayGame}
            className="flex justify-center items-center mt-4 px-[20px] h-[40px] flex-shrink-0 bg-[#F6C334] text-lg"
            shadowColor={'#a27700'}
          >
            <span className="text-white stroke-1 stroke-black">Play Game</span>
          </Button>
          <Button
            onClick={handleViewReward}
            className={cn(
              'flex justify-center items-center px-[20px] h-[40px] flex-shrink-0 bg-[#F6C334] text-lg mt-4',
            )}
            shadowColor={'#a37800'}
          >
            <span className="text-black">
              {isRedirecting ? 'Loading...' : 'My Rewards'}
            </span>
          </Button>
        </>
      )}
    </>
  );
};

const FailedContent = ({
  code,
  err,
  handleClaim,
  isClaiming,
  handleCodeChange,
}) => (
  <>
    <div className="w-full mb-4 font-bold text-2xl text-center text-[#e2e2e2] stroke-1 stroke-black">
      Oops! Incorrect cipher!
    </div>
    <div className="flex flex-row justify-center items-top">
      <InputSection code={code} err={err} handleCodeChange={handleCodeChange} />
      <Button
        onClick={handleClaim}
        className={cn(
          'flex justify-center items-center ml-1 w-[100px] h-[40px] bg-[#cbe513] text-lg',
        )}
        shadowColor={'#737b31'}
      >
        <span className="text-white stroke-1 stroke-black">
          {isClaiming ? 'Claiming...' : 'Claim'}
        </span>
      </Button>
    </div>
    <EasterEggShare />
  </>
);

const AlreadyClaimedContent = ({
  easterEggInfo,
  handlePlayGame,
  handleViewReward,
  isRedirecting,
}) => (
  <>
    <div className="w-full mb-4 font-bold text-2xl text-center text-[#e2e2e2] stroke-1 stroke-black">
      Oops! You have already claimed today!
    </div>
    <BonusDisplay />
    {easterEggInfo?.pass ? (
      <>
        <EasterEggShare />
        <Button
          onClick={handleViewReward}
          className={cn(
            'flex justify-center items-center px-[20px] h-[40px] flex-shrink-0 bg-[#F6C334] text-lg mt-4',
          )}
          shadowColor={'#a37800'}
        >
          <span className="text-black">
            {isRedirecting ? 'Loading...' : 'My Rewards'}
          </span>
        </Button>
      </>
    ) : (
      <>
        <div className="mt-4 bg-[#fceced] rounded-[6px] text-sm text-black text-center p-2 border-2 border-black">
          Complete your first expedition to reveal the cipher, share it, and
          earn 5,000-20,000 Pina Coins per claim!
        </div>
        <Button
          onClick={handlePlayGame}
          className="flex justify-center items-center mt-4 px-[20px] h-[40px] flex-shrink-0 bg-[#F6C334] text-lg"
          shadowColor={'#a27700'}
        >
          <span className="text-white stroke-1 stroke-black">Play Game</span>
        </Button>
        <Button
          onClick={handleViewReward}
          className={cn(
            'flex justify-center items-center px-[20px] h-[40px] flex-shrink-0 bg-[#F6C334] text-lg mt-4',
          )}
          shadowColor={'#a37800'}
        >
          <span className="text-black">
            {isRedirecting ? 'Loading...' : 'My Rewards'}
          </span>
        </Button>
      </>
    )}
  </>
);

const DefaultContent = ({
  type,
  code,
  err,
  eggInfo,
  isClaiming,
  isRedirecting,
  handleClaim,
  handleViewReward,
  handleCodeChange,
}) => {
  const { toast } = useToast();
  const { copyToClipboard } = useClipboard();
  const [, setIsCopiedEasterEggPass] = useAtom(isCopiedEasterEggPassAtom);

  return (
    <>
      <div
        className="flex flex-row w-full mb-2 font-bold text-base text-left items-center"
        onClick={() => {
          window.open(
            'https://www.youtube.com/watch?v=VHpXjr7_KMI',
            '_blank',
            'noopener,noreferrer',
          );
        }}
      >
        {/*  <Popover>*/}
        {/*    <PopoverTrigger>*/}
        {/*      <Image*/}
        {/*        className="inline-block mr-1 w-[20px] h-[20px]"*/}
        {/*        width={30}*/}
        {/*        height={30}*/}
        {/*        src={getPublicAsset('/images/easter-egg/info.svg')}*/}
        {/*        alt="info"*/}
        {/*      />*/}
        {/*    </PopoverTrigger>*/}
        {/*    <PopoverContent className="font-comicbd text-xs text-white bg-[#453b4c] border-0 outline-0">*/}
        {/*      <p>• Pass 1st expedition to reveal your today's cipher. </p>*/}
        {/*      <p>• Claim it once every 24 hours. </p>*/}
        {/*      <p>• Share your cipher with friends to earn rewards! </p>*/}
        {/*    </PopoverContent>*/}
        {/*  </Popover>*/}
        <span className="text-base mr-1">Pass 1st Expedition, Decrypt!</span>
        <Image
          className="inline-block mr-1 w-[20px] h-[20px]"
          width={30}
          height={30}
          src={getPublicAsset('/images/easter-egg/info.svg')}
          alt="info"
        />
      </div>
      <div className="flex flex-col w-full space-y-2 justify-center items-center">
        <InputSection
          type={type}
          code={code}
          err={err}
          handleCodeChange={handleCodeChange}
        />
        <Button
          onClick={handleClaim}
          className={cn(
            'flex justify-center items-center w-[160px] h-[40px] bg-[#cbe513] text-lg',
          )}
          shadowColor={'#737b31'}
        >
          <span className="text-white stroke-1 stroke-black">
            {isClaiming ? 'Claiming...' : 'Claim'}
          </span>
        </Button>
      </div>
      <EasterEggShare />
      <Button
        onClick={handleViewReward}
        className={cn(
          'flex justify-center items-center px-[20px] h-[40px] flex-shrink-0 bg-[#F6C334] text-lg mt-4',
        )}
        shadowColor={'#a37800'}
      >
        <span className="text-black">
          {isRedirecting ? 'Loading...' : 'My Rewards'}
        </span>
      </Button>
    </>
  );
};
