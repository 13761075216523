const env = {
  name: 'localhost',
  isLocal: true,
  hostname: 'localhost',
  internalDomains: {
    localhost: 1,
  },
  endpoints: {
    domain: 'localhost',
    assets: '',
    baseAPI: 'https://api.beta.pinamania.com',
    websocket: 'wss://api.beta.pinamania.com/api/game-ws',
    imgUploadUrl: '',
    imgThumbnailUrl: '',
    strapi: 'https://api.blog.pinamania.com/',
  },
  fingerPointDeviceHost: 'https://api.commonservice.io',
  polaris: {
    host: '',
    appId: '',
    saasId: '',
    token: '',
  },
  // Uncomment for use with `smartling-context-capture` package
  useSmartlingContextCapture: true,
};

module.exports = env;
