import { InternalAxiosRequestConfig } from 'axios';
import type { Interceptor } from './types';

const absoluteNoAuthUrlList = [];
// const methodGetNeedAuthUrlList = [''];

const addBodyForPost: Interceptor<InternalAxiosRequestConfig> = [
  (config: InternalAxiosRequestConfig) => {
    if (config.method === 'post' && !config.data) {
      config.data = {};
    }
    return config;
  },
];

export default addBodyForPost;
