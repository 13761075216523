export enum TIME_MAPS {
    /* in seconds */
    SECOND = 1,
    MINUTE = 60,
    HOUR = 60 * 60,
    DAY = 60 * 60 * 24,
    WEEK = 60 * 60 * 24 * 7,
    MONTH = 60 * 60 * 24 * 30,
    YEAR = 60 * 60 * 24 * 30 * 12,
    /* ms: {} same as above but in miliseconds */
}

export const BET_RECORD_DEFAULT_PAGE_SIZE = 50;
export const API_RESPONSE_SUCCESS_CODE = 0;
