import React, { FC, useState, useEffect } from 'react';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import { useLockBodyScroll } from '@uidotdev/usehooks';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useMyNewRanks } from '@/apis/rakings';
import { useAtom, useStore } from 'jotai';
import { userInfoAtom } from '@/store/userInfo';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import { cn } from '@/lib/utils';
import { track } from '@/utils/thinkdata/track';

import './rankings.css';

const redRibbonBg = getPublicAsset('/images/lobby/red-ribbon.png');
const rakingBg = getPublicAsset('/images/lobby/raking-bg.png');
const heartImg = getPublicAsset('/images/lobby/heart.png');
const decorateImg = getPublicAsset('/images/lobby/decorate.png');
const closeImg = getPublicAsset('/images/lobby/close.png');
const rakingsBtnImg = getPublicAsset('/images/lobby/rakings-icon.png');

const RANK_CUP_IMAGE = {
  '1': getPublicAsset('/images/rankings/No1.svg'),
  '2': getPublicAsset('/images/rankings/No2.svg'),
  '3': getPublicAsset('/images/rankings/No3.svg'),
};

const RANK_CUP_BG_CLASS = {
  '1': 'bg-[#FDC239]',
  '2': 'bg-[#FDC239] opacity-50',
  '3': 'bg-[#FDC239] opacity-50',
};

const RANK_BG_CLASS = {
  '1': 'bg-[#FFD648]',
  '2': 'bg-[#FFD648] opacity-60',
  '3': 'bg-[#FFD648] opacity-60',
};

// const mapListItem = ({
//   rank,
//   name,
//   headerUrl,
//   bananaCount,
//   registerCity,
//   registerCountry,
// }) => {
//   return {
//     rank,
//     avator: headerUrl,
//     name,
//     passes: bananaCount,
//     city: registerCity,
//     country: registerCountry,
//   };
// };
//
// const RegisterCountryName = ({ city, country }) => {
//   return (
//     <div className="flex flex-col max-w-[118px] truncate text-left ml-2">
//       <div className="text-sm font-bold"> {city} </div>
//       <div className="text-xs"> {country} </div>
//     </div>
//   );
// };

const ActiveCategoryTab = ({ text }) => {
  return (
    <div className="relative w-[106%] -translate-x-[3%] h-full cursor-pointer capitalize">
      <div className="absolute w-[94%] left-[3%] top-[2px] rounded-[0.25rem] bg-[#BEE3FF] h-[72%] z-10"> { text } </div>
      <Image className="absolute w-full left-0 top-0" width={219} height={86} src={getPublicAsset('/images/rankings/active-category-tab.svg')} alt="active tab" />
      <Image className="absolute left-1/2 bottom-0 -translate-x-1/2" width={41} height={28} src={getPublicAsset('/images/rankings/triangle.svg')} alt="triangle" />
    </div>
  );
}

const CategoryTab = ({ text, onClick }) => {
  return (
    <BaseInteractive onInteractiveClick={onClick}>
      <div className="flex capitalize active:translate-y-0.5 active:translate-x-0.5 justify-center items-center h-full opacity-20 cursor-pointer">
        {text}
      </div>
    </BaseInteractive>
  );
}

const Avatar = ({ logo, show }) => {
  return (
    <>
      {
        show ? (
          <div className="relative w-[40px] h-[40px] rounded-full overflow-hidden">
            <Image fill src={logo || getPublicAsset('/images/rankings/default-avatar.png')} alt="avatar" />
          </div>
        ) : null
      }
    </>
  );
};

function RankingsDialog({ closeHandler }: { closeHandler?: () => void }) {
  const t = useTranslations();
  const store = useStore();
  const userInfo = store.get(userInfoAtom);
  const categoryTabs = ['friends', 'players', 'citys'];
  // const rangeTabs = ['day', 'week', 'month', 'all'];
  const rangeTabs = ['day', 'week', 'month'];
  const [activeCategoryTab, setActiveCategoryTab] = useState(categoryTabs[0]);
  const [activeRangeTab, setActiveRangeTab] = useState(rangeTabs[0]);
  const TAB_MAP = {
    'friends': 'friend',
    'players': 'user',
    'servers': 'city',
    'citys': 'city',
    'day': 'today',
    'week': 'week',
    'month': 'month',
    'all': 'all',
  };
  // @ts-ignore
  const [userRank, setUserRank] = useState({
    rank: '',
    logo: userInfo.headerUrl,
    name: userInfo.name,
    count: userInfo.bananaCount,
  });
  const [list, setList] = useState([]);
  const { data: ranks, isLoading } = useMyNewRanks();

  useLockBodyScroll();

  const updateList = () => {
    const rankInfo = ranks?.rankInfos?.find(rank => {
      return rank.rankType === `${TAB_MAP[activeCategoryTab]}_${TAB_MAP[activeRangeTab]}`;
    });
    setList(rankInfo?.list);
    setUserRank(rankInfo?.me);
  };

  useEffect(() => {
    updateList();
  }, [ranks, activeCategoryTab, activeRangeTab]);

  useEffect(() => {
    track('ranking_check');
  }, []);

  return (
    <div className={`fixed flex items-center justify-center overscroll-none left-0 top-0 w-screen h-screen font-comicbd z-100`}>
      <div
        onClick={closeHandler}
        className="absolute w-full h-full opacity-60 bg-[#000]"
      />
      <div className="relative m-auto w-[350px]">
        <Image className="absolute top-[12px] left-1/2 -translate-x-1/2 -translate-y-full z-30 w-[160px]" width={339} height={137} src={getPublicAsset('/images/rankings/rankings-top-front.png')} alt="rankings-top-front" />
        <Image className="absolute top-[15px] left-[2px] -translate-y-full z-10 w-full" width={712} height={217} src={getPublicAsset('/images/rankings/rankings-top.png')} alt="rankings-top" />
        <div className="absolute top-[8px] left-1/2 text-xl text-[#ffd647] capitalize stroke-black -translate-x-1/2 -translate-y-full z-30 w-[160px]">
          {t('rankings')}
        </div>
        <BaseInteractive onInteractiveClick={closeHandler}>
          <Image className="absolute top-[-16px] right-[-16px] w-[32px] h-[32px] z-50 active:translate-y-0.5 active:translate-x-0.5" width={40} height={40} src={closeImg} alt="close" />
        </BaseInteractive>
        <Card
          className="flex flex-col relative bg-[#f6d95e] w-full h-full z-20"
          backgroundColor="#f6d95e"
          shadowColor="#ee9b40"
        >
          <div className="relative h-[40px]">
            <div className="absolute left-0 top-0 w-full h-full grid grid-cols-3 items-center z-20">
              {
                categoryTabs.map((tab) => {
                  const text = t(tab);
                  return (
                    <>
                      {
                        tab === activeCategoryTab ? (
                          <ActiveCategoryTab key={text} text={text} />
                        ) : (
                          <CategoryTab key={text} text={text} onClick={() => setActiveCategoryTab(tab)} />
                        )
                      }
                    </>
                  )
                })
              }
            </div>
            <div className="absolute left-0 top-0 w-full grid grid-cols-3 h-full bg-[#DA8E13] rounded-[0.75rem] border-[2px] border-solid border-[#FFF7D5] opacity-50 z-10" />
            <div className="absolute left-[33.3%] top-[20%] w-[1px] h-[60%] bg-[#FFF] opacity-60 rounded-full z-10" />
            <div className="absolute left-[66.6%] top-[20%] w-[1px] h-[60%] bg-[#FFF] opacity-60 rounded-full z-10" />
          </div>
          <div className="relative h-[1px] border-t-[2px] border-dashed border-[#B8752C4D] mt-3 mb-3" />
          <div className="grid grid-cols-3 gap-1 px-2 z-30">
            {rangeTabs.map((tab) => {
              const tabClass = tab === activeRangeTab ? `bg-[#FFF6E1]` : `bg-[#DA8E13] opacity-40`;
              return (
                <BaseInteractive
                  key={tab}
                  onInteractiveClick={() => {
                    track('ranking_type', {
                      rank_type: tab,
                    });
                    setActiveRangeTab(tab);
                  }}
                >
                  <div className={cn('cursor-pointer uppercase border-2 border-black border-solid text-center pt-1 pb-3 rounded-[0.5rem] active:translate-y-0.5 active:translate-x-0.5', tabClass)}>
                    {t(tab)}
                  </div>
                </BaseInteractive>
              );
            })}
          </div>
          <div className="flex flex-col -mt-2 text-[#010101] bg-[#FFF6E1] border-black rounded-md border-2 z-40 py-1 px-2">
            <div className="flex flex-row w-full text-sm text-[#666] px-2">
              <div className="w-[48px] text-center capitalize"> {t('rank')} </div>
              <div className="flex-1 text-left capitalize ml-3"> {t('name')} </div>
              <div className="w-[76px] text-right capitalize"> pinas </div>
            </div>
            <div className="bg-[#F9EED3] p-1 rounded-md h-[240px] overflow-x-hidden overflow-y-auto overscroll-contain">
              {
                isLoading ? (
                  <div className="flex w-full h-full justify-center items-center opacity-50">
                    Loading...
                  </div>
                ) : !list || list?.length === 0 ? (
                  <div className="flex w-full h-full justify-center items-center opacity-50">
                    No data yet
                  </div>
                ) : null
              }
              {list?.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className="relative flex flex-row items-center h-[56px] border-[#C09A50] rounded-[0.5rem] border-2 z-20 mt-1 overflow-hidden"
                  >
                    <div className={cn("absolute w-full h-full left-0 top-0", RANK_BG_CLASS[item.rank])} />
                    {Number(item.rank) < 4 ? (
                      <div className="flex relative w-[56px] h-[56px] items-center justify-center z-[10]">
                        <div className={cn("absolute w-[56px] h-[56px] left-0 top-0", RANK_CUP_BG_CLASS[item.rank])} />
                        <div className="absolute w-[40px] h-[40px]">
                          <Image fill src={RANK_CUP_IMAGE[item.rank]} alt="rankCup" />
                        </div>
                      </div>
                    ) : (
                      <div className="relative flex w-[56px] h-[56px] text-white items-center justify-center z-[10]">
                        <span className="text-xl stroke-gray z-10"> {item.rank} </span>
                        <div className={cn("absolute w-[56px] h-[56px] left-0 top-0", "bg-[#FEE5AA]")} />
                      </div>
                    )}
                    <div className="flex flex-row ml-1 items-center truncate">
                      <Avatar logo={item?.logo} show={activeCategoryTab !== 'citys'} />
                    </div>
                    <div className="relative flex flex-1 truncate justify-start items-center font-bold ml-1">
                      {item.name || 'Anonymous'}
                    </div>
                    <div className="relative flex truncate w-[64px] h-[56px] mr-2 justify-end items-center font-bold">
                      {item.count}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-row text-xs w-full items-center justify-center text-black capitalize">
              {t('your ranking')}
            </div>
            <div className="relative flex flex-row items-center h-[56px] border-[#7BBBEC] rounded-[0.5rem] border-2 z-20 mt-1 mb-2 overflow-hidden">
              <div className={cn("absolute w-full h-full left-0 top-0", "bg-[#BEE3FF]")} />
              <div className="relative flex w-[56px] h-[56px] text-white items-center justify-center z-[10]">
                <span className="text-xl stroke-black z-10">
                  {Number(userRank?.rank) > 999 ? '999+' : userRank?.rank}
                </span>
                <div className={cn("absolute w-[56px] h-[56px] left-0 top-0", "bg-[#64B2F1]")} />
              </div>
              <div className="flex flex-row ml-1 items-center truncate">
                <Avatar logo={userRank?.logo} show={activeCategoryTab !== 'citys'} />
              </div>
              <div className="relative flex flex-1 truncate justify-start items-center font-bold ml-1">
                {userRank?.name || 'Anonymous'}
              </div>
              <div className="relative flex truncate w-[64px] h-[56px] mr-2 justify-end items-center font-bold">
                {userRank?.count}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default RankingsDialog;
