import configs from '@/configs';
import { URLS } from '@/constants/biz';

export const isInternalDomain = (domain: string) => {
  return (
    configs.env?.endpoints?.domain?.includes(domain) ||
    Object.values(URLS).find(
      (url) => Array.isArray(url) && url.find((u) => u.includes(domain)),
    )
  );
};

export default isInternalDomain;
