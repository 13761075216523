import { useAtom } from 'jotai';
import { isCopiedEasterEggPassAtom } from '@/store/runtime';
import { useClipboard } from '@/hooks/useClipboard';
import { Button } from '@/components/ui/button';

export const InputSection = ({ type = 'text', code, err, handleCodeChange }) => {
  const [isCopiedEasterEggPass] = useAtom(isCopiedEasterEggPassAtom);
  const { pasteFromClipboard, state } = useClipboard();
  const handlePaste = async (event) => {
    event.preventDefault();
    const text = await pasteFromClipboard();
    if (text.includes('http')) {
      const link = text.match(/https?:\/\/[^\s]+/)[0];
      handleCodeChange(link?.slice(-5));
    } else {
      handleCodeChange(text);
    }
  };

  return (
    <div className="w-full">
      <div className="relative w-full">
        <input
          value={code}
          type={type}
          pattern={type === 'number' ? '[0-9]*' : '.*'}
          onChange={(e) => handleCodeChange(e.target.value)}
          placeholder="Enter the cipher..."
          autoFocus
          className="border h-[40px] rounded-[10px] border-[#6B6454] border-r border-l bg-[#574c5b] text-white outline-none w-[100%] px-[10px]"
          onPaste={handlePaste}
        />
        {isCopiedEasterEggPass ? (
          <Button
            className="absolute text-xs text-black w-[54px] h-[32px] top-[0.15rem] right-0 bg-[#cbe513]"
            shadowColor="#737b31"
            onClick={handlePaste}
          >
            Paste
          </Button>
        ) : null}
      </div>
      {err ? (
        <div className="text-xs text-red-500">{err}</div>
      ) : (
        <div className="text-xs text-black">The Cipher updates every 24h.</div>
      )}
    </div>
  );
};
