import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import gsap from 'gsap';
import Image from 'next/image';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { useOverview, type CityDTOItem } from '@/apis/rakings';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';

const bananaImg = getPublicAsset('/images/lobby/pinaple_sm.png');
const coinsImg = getPublicAsset('/images/lobby/coins.png');
const bubbleImg = getPublicAsset('/images/lobby/bubble.png');
const crownImg = getPublicAsset('/images/lobby/crown2.png');
const conchImg = getPublicAsset('/images/lobby/conch.png');
const littleConchImg = getPublicAsset('/images/lobby/little-conch.png');
const starfishImg = getPublicAsset('/images/lobby/starfish.png');

// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
function shuffle(array) {
  let currentIndex = array.length;
  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
}

const MAX_CIRCLE_COUNT = 18;

const bubbleAmp = (index, amp) => {
  if (index < MAX_CIRCLE_COUNT) {
    return amp;
  }
  return amp - 8;
};

const circleCount = (len, index) => {
  if (len <= MAX_CIRCLE_COUNT) {
    return len;
  }
  if (index < MAX_CIRCLE_COUNT) {
    return MAX_CIRCLE_COUNT;
  } else {
    return len - MAX_CIRCLE_COUNT;
  }
};

const _randomOne = () => {
  return _random(2) - 1;
};

const _random = (n) => {
  return Math.floor(Math.random() * n);
};

const randomPosition = (len, index, isLeft = false) => {
  let m = 3;
  if (len < MAX_CIRCLE_COUNT) {
    m = 6;
  } else if (len < MAX_CIRCLE_COUNT / 2) {
    m = 12;
  }
  return isLeft ?
    -1 * _random(m) * Math.sin(2 * Math.PI * (index / circleCount(len, index))) :
    _random(m) * Math.cos(2 * Math.PI * (index / circleCount(len, index)))
};

const genBubblePosition = (len) => {
  const _positions = [];
  for (let i = 0; i < len; i += 1) {
    _positions.push({
      left: `${45 + _randomOne() + randomPosition(len, i, true) + bubbleAmp(i, 26) *
        Math.sin(2 * Math.PI * (i / circleCount(len, i)))}%`,
      // 27 46 65 [19]
      top: `${45 + _randomOne() + randomPosition(len, i) - bubbleAmp(i, 22) *
        Math.cos(2 * Math.PI * (i / circleCount(len, i)))}%`,
    });
  }
  return _positions;
}

const bubblePositions = genBubblePosition(30);
shuffle(bubblePositions);

const Bubble = ({ user, position, crown = false }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Popover>
      <PopoverTrigger
        className={cn(
          "absolute text-center",
          isLoaded ? "animate-in zoom-in duration-500" : "opacity-0"
        )}
        style={position}
      >
        <div className={cn("relative p-1 inline-block rounded-full",
          crown ? "w-[3.2rem] " : "w-[2.4rem] ")}>
          <Image
            className={cn(
              "relative rounded-full z-10",
              !isLoaded && "opacity-0"
            )}
            width={50}
            height={50}
            src={
              user.headerUrl ||
              getPublicAsset(`/images/lobby/pinaple-sunglasses1.png`)
            }
            onLoad={() => {
              setIsLoaded(true);
            }}
            alt={`banana`}
          />
          <Image
            className="absolute w-full left-0 top-0"
            width={50}
            height={50}
            sizes="80vw"
            src={bubbleImg}
            alt="bubble"
          />
          {crown && (
            <Image
              className="absolute w-full left-0 -top-6 z-10"
              width={64}
              height={50}
              sizes="80vw"
              src={crownImg}
              alt="crown"
            />
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="!w-auto min-w-[8rem] text-[#86690C] text-xs p-2 border-2 border-black divide-solid ">
        <div className="space-y-2">
          <div className="text-[16px]">{user?.name}</div>
          <div className="text-[15px] flex items-center text-wrap font-comicbd">
            <Image
              className="inline-block w-[20px] mr-1"
              width={20}
              height={20}
              src={bananaImg}
              alt="banana"
              priority
            />
            <span className="mr-3">{user?.bananaCount}</span>
            <Image
              className="inline-block w-[25px] mr-1"
              width={20}
              height={20}
              src={coinsImg}
              alt="coins"
              priority
            />
            <span>{user?.gold}</span>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}

const FloatingRings = () => {
  const { data: overviewData, isLoading } = useOverview();
  const [refs, setRefs] = useState([]);

  useEffect(() => {
    if (overviewData?.data?.overviewDTO?.cityDTOList) {
      setRefs(
        overviewData.data?.overviewDTO?.cityDTOList?.map(() =>
          React.createRef<HTMLDivElement>(),
        ),
      );
    }
  }, [overviewData]);

  if (isLoading || !refs.length) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full select-none mb-[10rem]">
      {overviewData?.data?.overviewDTO?.cityDTOList?.map((item: CityDTOItem, index: number) => (
        <LazyLoadedRing key={index} item={item} index={index} ringRef={refs[index] || null} />
      ))}
    </div>
  );
};


const LazyLoadedRing = ({ item, index, ringRef }: { item: CityDTOItem, index: number, ringRef: React.RefObject<HTMLDivElement> }) => {
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  useLayoutEffect(() => {
    if (ringRef?.current && inView) {
      gsap.to(ringRef.current, {
        y: gsap.utils.random(-20, 20),
        x: gsap.utils.random(-20, 20),
        rotation: gsap.utils.random(-15, 15),
        duration: gsap.utils.random(3, 6),
        ease: 'sine.inOut',
        repeat: -1,
        yoyo: true,
        transformOrigin: '50% 50%',
      });
    }
  }, [ringRef, inView]);

  return (
    <div ref={ref}>
      {(
        <div className="relative">
          <Image className="absolute scale-[0.7] -left-2 top-0" width={116} height={98} src={starfishImg} alt="starfish" />
          <Image className="absolute scale-[0.7] left-0 bottom-10" width={64} height={64} src={littleConchImg} alt="little conch" />
          <Image className="absolute scale-[0.7] right-0 bottom-0" width={40} height={60} src={conchImg} alt="conch" />
          <div
            ref={ringRef}
            className="relative inline-block w-full max-w-[40rem] mb-[1rem]"
            style={{ willChange: 'transform' }}
          >
            <Image
              width={512}
              height={696}
              sizes='80vw'
              className="pointer-events-none w-[80%] mx-auto"
              src={getPublicAsset(`/images/lobby/Swimming_04.png`)}
              alt={`Swimming Ring`}
            />
            <div className="absolute whitespace-nowrap w-[32px] h-[32px] top-[7%] right-1/2 translate-x-[35%] text-2xl text-black flex justify-center items-center font-bold z-10">
              {item.countryRank}
            </div>
            <div className="absolute font-comicbd break-words top-[14%] right-1/2 translate-x-1/2 flex flex-col justify-center items-center w-[240px] h-[32px]">
              <p className={'text-xs text-white stroke-black'}>{item.city}</p>
              <p className={'text-xs text-white'}>{item.country}</p>
            </div>
            <div className="absolute whitespace-nowrap top-[74%] w-[160px] h-[48px] right-1/2 translate-x-1/2 flex items-center justify-center z-10">
              <Image
                width={48}
                height={48}
                className="rotate-[27deg] pointer-events-none inline-block -translate-y-1"
                src={getPublicAsset(`/images/lobby/pinaple.png`)}
                alt={`banana`}
              />
              <span className="font-bold">
                {item.totalScore}
              </span>
            </div>
            <div>
              {inView &&
                item.userList.slice(0, 29).map((v, i) => {
                  if (i === 0) {
                    return (
                      <Bubble
                        key={i}
                        user={v}
                        crown={true}
                        position={{
                          left: '45%',
                          top: '45%'
                        }}
                      />
                    )
                  }
                  return (
                    <Bubble
                      key={i}
                      user={v}
                      position={bubblePositions[i - 1]}
                    />
                  )
                })
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FloatingRings;
