import request from '@/http/request';
import { useQuery } from '@tanstack/react-query';
import { isProduction } from '@/utils/checkRuntime';

export interface EasterEggUserRewardItem {
  tgId: string; // 领取我的彩蛋的用户id
  gold: string; // 金币奖励
  bananaId: string; // 菠萝奖励
  name: string; // 领取我的彩蛋的用户名
  headUrl: string;
}

export interface EasterEggDataDTO {
  tgId: string;
  pass: string; // 彩蛋密码
  expireTime: string; // 彩蛋过期时间
  myBananaId: string;
  myGold: string;
  users: EasterEggUserRewardItem[];
  link: string; // 彩蛋链接
}

export const fetchEasterEggBonus = (pass: string) => {
  return request({
    url: '/api/mini-api/easter-egg/get-bonus',
    data: { pass },
    method: 'post',
    suppressWarning: false,
  });
};

export const fetchEasterEggInfo = () => {
  return request<{
    easterEggDataDTO: EasterEggDataDTO;
  }>({
    url: '/api/mini-api/easter-egg/info',
    data: {},
    method: 'post',
    suppressWarning: false,
  });
};

export const useEasterEggInfo = () => {
  return useQuery({
    queryKey: ['eggInfo'],
    queryFn: () => fetchEasterEggInfo()
      .then(res => res.data.easterEggDataDTO),
    staleTime: 0,
  });
};
