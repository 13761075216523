import Image from 'next/image';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'next/navigation';
import { useAtom } from 'jotai';
import { isEasterEggFirstTimeAtom } from '@/store/runtime';
import { userInfoAtom, userEasterEggInfoAtom } from '@/store/userInfo';
import { useLocalStorage } from "@uidotdev/usehooks";
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import { initUtils } from '@telegram-apps/sdk';
import { getEasterEggCode } from '@/helper/getCode';
import { Button } from '@/components/ui/button';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { LOCAL_KEY_EASTER_EGG_SHARE_COUNT } from "@/constants/localStorageKeys";
import addComma from '@/utils/number/addComma';
import { cn } from '@/lib/utils';

export const EasterEggPopup = ({ handleViewReward, isRedirecting }) => {
  const searchParams = useSearchParams();
  const [show, setShow] = useState(false);
  const [easterEggInfo] = useAtom(userEasterEggInfoAtom);
  const [isEasterEggFirstTime, setIsEasterEggFirstTime] = useAtom(
    isEasterEggFirstTimeAtom,
  );
  const [userInfo] = useAtom(userInfoAtom);
  const [localPlayers, saveLocalPlayers] = useLocalStorage(LOCAL_KEY_EASTER_EGG_SHARE_COUNT, 0);
  const utils = initUtils();

  const users = useMemo(() => {
    return easterEggInfo?.users?.filter((user) => user.tgId !== userInfo?.tgId);
  }, [easterEggInfo]);

  const { players, earnedCoins } = useMemo(() => {
    return {
      players: users?.length,
      earnedCoins: users?.map((u) => Number(u.gold)).reduce((a, b) => a + b, 0),
    };
  }, [easterEggInfo]);

  useEffect(() => {
    const easterEggShareCode = getEasterEggCode(searchParams);
    if (easterEggShareCode) {
      setIsEasterEggFirstTime(false);
      return;
    }
    if (localPlayers < players) {
      setShow(easterEggInfo?.pass && users?.length > 0);
      saveLocalPlayers(players);
    }
  }, [easterEggInfo]);

  const _close = () => {
    setIsEasterEggFirstTime(false);
    setShow(false);
  };

  if (!isEasterEggFirstTime) {
    return null;
  }

  return (
    <AlertDialog open={show}>
      <AlertDialogContent
        className={cn(
          'relative flex flex-col justify-center items-center bg-[#feefc4] rounded-[16px] w-9/12 p-0 font-comicbd',
        )}
        enable3DBorder={false}
      >
        <BaseInteractive onInteractiveClick={_close}>
          <div className="closeButton absolute top-[-18px] right-[-18px] w-[40px] h-[40px] z-20 active:translate-y-0.5 active:translate-x-0.5">
            <Image
              fill
              src={getPublicAsset('/images/lobby/close.png')}
              alt="close"
            />
          </div>
        </BaseInteractive>
        <Image
          width={598}
          height={848}
          className="w-full"
          src={getPublicAsset('/images/easter-egg/easter-egg-popup-bg.png')}
          alt="easter-egg-popup-bg"
        />
        <Image
          className="absolute w-[21px] h-[25px] left-[-10px] top-[64px]"
          src={getPublicAsset('/images/easter-egg/star.png')}
          width={42}
          height={50}
          alt="easter star"
        />
        <Image
          className="absolute w-[21px] h-[25px] right-[-10px] bottom-[64px]"
          src={getPublicAsset('/images/easter-egg/star.png')}
          width={42}
          height={50}
          alt="easter star"
        />
        <div className="absolute flex flex-col w-full h-1/2 top-[40%] px-4 text-center justify-center items-center">
          <div className="mb-2 uppercase text-xl">Congratulations!</div>
          <div className="mb-2 text-base text-[#721f9b]">
            <span className="text-[#ec1dc6]"> {players} </span>
            <span>{`players claimed rewards through your cipher`}</span>
          </div>
          <div className="flex mb-2 justify-between items-center px-2 w-full border-2 border-black rounded-[8px] h-[40px]">
            <div className="text-[#721f9b]"> Earning you</div>
            <div className="flex">
              <Image
                className=""
                width={24}
                height={24}
                src={getPublicAsset('/images/lobby/coins.png')}
                alt="coins"
              />
              <span className="ml-1 text-base">
                {' '}
                {addComma(earnedCoins?.toString())}{' '}
              </span>
            </div>
          </div>
          <Button
            onClick={() => {
              setIsEasterEggFirstTime(false);
              handleViewReward();
            }}
            className={cn(
              'flex justify-center items-center px-[20px] h-[40px] flex-shrink-0 bg-[#F6C334] text-lg mt-4',
            )}
            shadowColor={'#a37800'}
          >
            <span className="text-black">
              {isRedirecting ? 'Loading...' : 'My Rewards'}
            </span>
          </Button>
          {
            // <Button
            //   onClick={() => {
            //     setShow(false);
            //     setIsEasterEggFirstTime(false);
            //   }}
            //   className="flex justify-center items-center w-[180px] h-[48px] bg-[#bdd10b] text-lg"
            //   shadowColor="#bdd10b"
            // >
            //   Got it
            // </Button>
          }
        </div>
        <AlertDialogTitle className="hidden">Rewards</AlertDialogTitle>
      </AlertDialogContent>
    </AlertDialog>
  );
};

