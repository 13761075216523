import Decimal from 'decimal.js-light';

// 所有操作符的最有一位必须是 Precision
// console.log(Add(0.1, [0.2, 0.0003], Precision.two)); // "0.60"
// console.log(Add(0.1, [0.2])([0.3, 0.4], Precision.two)); // "1.00"
// console.log(Add([0.1])([0.2])([0.3], Precision.two)); // “0.60"

export enum Precision {
  inter = 0,
  one = 1,
  two = 2,
  four = 4,
  six = 6,
  eight = 8,
  ten = 10,
  twelve = 12,
  sixteen = 16,
}

type Operation = (a: Decimal, b: Decimal, precision?: number) => Decimal;
type CurriedOperation = (
  ...args: (number | string | (number | string)[])[]
) => any;

const flattenArgs = (
  args: (number | string | (number | string)[])[],
): (number | string)[] => {
  return args.reduce<(number | string)[]>((acc, arg) => {
    if (Array.isArray(arg)) {
      acc.push(...arg);
    } else {
      acc.push(arg);
    }
    return acc;
  }, []);
};

const isPositiveInteger = (value: number): boolean => {
  return Number.isInteger(value) && value > 0;
};

const wrapOperation =
  (operation: Operation): CurriedOperation =>
  (...args) => {
    const flattenedArgs = flattenArgs(args);

    if (flattenedArgs.length === 0) {
      throw new Error('At least one argument is required');
    }

    if (flattenedArgs.length === 1) {
      return (...moreArgs: (number | string | (number | string)[])[]) =>
        wrapOperation(operation)(
          ...flattenedArgs.concat(flattenArgs(moreArgs)),
        );
    }

    const defaultPrecision = 4;
    let precision = defaultPrecision;

    if (typeof flattenedArgs[flattenedArgs.length - 1] === 'number') {
      precision = flattenedArgs.pop() as number;
      if (!isPositiveInteger(precision)) {
        precision = defaultPrecision;
      }
    }

    try {
      const decimals = flattenedArgs.map((arg) => new Decimal(arg));
      const result = decimals.reduce((acc, cur) =>
        operation(acc, cur, precision),
      );

      if (typeof result === 'function') {
        return result;
      }

      return result.toDecimalPlaces(precision).toString();
    } catch (e) {
      console.error('🍎🍎🍎error value:', flattenedArgs, e);
      // throw new Error(e);
    }
  };

const add: Operation = (a, b, precision) => {
  const result = a.plus(b);
  return precision ? result.toDecimalPlaces(precision) : result;
};

const subtract: Operation = (a, b, precision) => {
  const result = a.minus(b);
  return precision ? result.toDecimalPlaces(precision) : result;
};

const multiply: Operation = (a, b, precision) => {
  const result = a.times(b);
  return precision ? result.toDecimalPlaces(precision) : result;
};

const divide: Operation = (a, b, precision) => {
  const result = a.dividedBy(b);
  return precision ? result.toDecimalPlaces(precision) : result;
};

export const Add = wrapOperation(add);
export const Subtract = wrapOperation(subtract);
export const Multiply = wrapOperation(multiply);
export const Divide = wrapOperation(divide);
