import { useEffect } from 'react';
import { useMiniApp, useThemeParams } from '@telegram-apps/sdk-react';

function fixColorString(color): `#${string}` {
  if (!color) return '#fff';
  if (color.startsWith('#')) {
    return color;
  } else {
    return `#${color}`;
  }
}

export const useMiniAppColor = (bgColor?: string, headerColor?: string) => {
  const miniApp = useMiniApp();
  const themeParams = useThemeParams();

  useEffect(() => {
    const h = headerColor ? headerColor : themeParams.headerBgColor;
    const bg = bgColor ? bgColor : themeParams.bgColor;
    miniApp.setHeaderColor(fixColorString(h));
    miniApp.setBgColor(fixColorString(bg));
  }, []);
};

export default useMiniAppColor;
