import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

// 更新英文本地化配置
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: function (number, withoutSuffix, key, isFuture) {
      if (number < 3) {
        return 'Now';
      } else {
        return `${number}s`;
      }
    },
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

/**
 * 动态导入指定的 dayjs 本地化文件，并将本地化配置应用到 dayjs 实例中
 * @param {string} locale 本地化语言标识符
 * @returns {Promise} 导入并应用本地化配置的 Promise
 * @example
 * convertToLocale('fr').then(() => {
 *   // dayjs 实例现在使用了法语的本地化配置
 * });
 */
export const convertToLocale = (locale) => {
  return import(`dayjs/locale/${locale}.js`).then(() => dayjs.locale(locale));
};

/**
 * 将给定的时间转换为相对时间字符串
 * @returns {string} 相对时间字符串
 * @example
 * const time = dayjs('2022-01-01');
 * const relativeTimeString = TimeAgo(time);
 * console.log(relativeTimeString); // 输出示例: "1 year ago"
 */
export const timeAgo = (time: string | number | Date | dayjs.Dayjs) => {
  const diffSeconds = dayjs().diff(time, 'second');
  const diffMinutes = dayjs().diff(time, 'minute');
  const diffHours = dayjs().diff(time, 'hour');
  const diffDays = dayjs().diff(time, 'day');
  const diffYears = dayjs().diff(time, 'year');

  if (diffMinutes < 1) {
    return `${diffSeconds}s`;
  }
  if (diffMinutes >= 1 && diffMinutes < 60) {
    return `${diffMinutes}m`;
  }
  if (diffMinutes >= 60 && diffHours < 24) {
    return `${diffHours}h`;
  }
  if (diffHours >= 24 && diffYears < 1) {
    return `${diffDays}d`;
  }
  return dayjs().to(dayjs(time), true);

  // if (diffYears < 0) {
  //   return dayjs().format();
  // }
  // if (diffHours < 24 && diffHours >= 22) {
  //   return `${diffHours}h`;
  // }
  // if (diffHours >= 24 && diffYears < 1) {
  //   // const nowYear = dayjs().year();
  //   // const timeYear = dayjs(time).year();
  //   return dayjs(time).format();
  // }
  // if (diffMinutes >= 60 && diffHours < 24) {
  //   return `${diffHours}h`;
  // }
  //
  // return dayjs().to(dayjs(time), true);
};

/**
 * 将给定的日期对象格式化为绝对时间字符串
 * @param {Date} date 给定的日期对象
 * @param {string} separator 日期和时间之间的分隔符，默认为 '[at]'
 * @param {string} separator 日期和时间之间的分隔符，默认为 '[at]'
 * @param {string}  formatTemplate 日期格式化模板，默认为 'MMMM DD, YYYY [at] h:mm A'
 * @returns {string} 绝对时间字符串
 * @example
 * const date = new Date();
 * const absoluteTimeString = absoluteTime(date, '-');
 * console.log(absoluteTimeString); // 输出示例: "June 15, 2023 - 3:30 PM"
 */
export const absoluteTime = (
  date: Date | string | number,
  separator = '',
  formatTemplate = `MMMM DD, YYYY ${separator} h:mm A`,
) => {
  return dayjs(date).format(formatTemplate);
};

/**
 * 将给定的时间转换为相对时间字符串
 * @param {Dayjs} time 给定的时间对象
 * @returns {string} 相对时间字符串
 * @example
 * const time = dayjs('2022-01-01');
 * const relativeTimeAgoString = relativeTimeAgo(time);
 * console.log(relativeTimeAgoString); // 输出示例: "a year ago"
 */
export const relativeTimeAgo = (time) => {
  return dayjs().to(dayjs(time));
};

/**
 * 得到n天/小时前的时间
 * @param retroCount
 * @param unit
 * @param now
 */
export const subtractTime = (
  retroCount: number,
  unit: 'd' | 'h',
  now = new Date().getTime(),
) => {
  return dayjs(now).subtract(retroCount, unit).toDate();
};
