export function romanToArabic(roman: string): number {
  const romanNumerals: { [key: string]: number } = {
    I: 1,
    V: 5,
    X: 10,
    L: 50,
    C: 100,
    D: 500,
    M: 1000,
  };

  let result: number = 0;
  let prevValue: number = 0;

  for (let i = roman.length - 1; i >= 0; i--) {
    const currentValue: number = romanNumerals[roman[i]];

    if (currentValue >= prevValue) {
      result += currentValue;
    } else {
      result -= currentValue;
    }

    prevValue = currentValue;
  }

  return result;
}

// 示例用法
console.log(romanToArabic('III')); // 输出: 3
console.log(romanToArabic('IV')); // 输出: 4
console.log(romanToArabic('IX')); // 输出: 9
console.log(romanToArabic('LVIII')); // 输出: 58
console.log(romanToArabic('MCMXCIV')); // 输出: 1994

export function arabicToRoman(num: number): string {
  const romanNumerals: [number, string][] = [
    [1000, 'M'],
    [900, 'CM'],
    [500, 'D'],
    [400, 'CD'],
    [100, 'C'],
    [90, 'XC'],
    [50, 'L'],
    [40, 'XL'],
    [10, 'X'],
    [9, 'IX'],
    [5, 'V'],
    [4, 'IV'],
    [1, 'I'],
  ];

  let result: string = '';

  for (const [value, symbol] of romanNumerals) {
    while (num >= value) {
      result += symbol;
      num -= value;
    }
  }

  return result;
}

// 示例用法
console.log(arabicToRoman(3)); // 输出: III
console.log(arabicToRoman(4)); // 输出: IV
console.log(arabicToRoman(9)); // 输出: IX
console.log(arabicToRoman(58)); // 输出: LVIII
console.log(arabicToRoman(1994)); // 输出: MCMXCIV
