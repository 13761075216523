import { ReadonlyURLSearchParams } from 'next/navigation';

/**
 * 自定义解析函数，直接将 'p0xa' 解析为 '&'，将 'p0xb' 解析为 '='
 *
 * @param encodedUrl - 编码后的URL或字符串
 * @returns 解析后的对象
 */
export function customDecode(encodedUrl: string): Record<string, string> {
  const params: Record<string, string> = {};

  // 按 'p0xa' 分割键值对
  const pairs = encodedUrl.split('p0xa');

  pairs.forEach((pair) => {
    // 按 'p0xb' 分割键和值
    const [key, value] = pair.split('p0xb');
    params[key] = decodeURIComponent(value || '');
  });

  return params;
}

/**
 * 使用正则表达式提取 'pina' 之后的所有数字
 *
 * @param input - 输入的字符串
 * @returns 提取出的数字字符串
 */
function extractNumbers(input: string): string | null {
  const regex = /(pina|banana)(\d+)/;
  const match = input.match(regex);
  return match ? match[2] : null;
}

export function getInviteCode(searchParams: ReadonlyURLSearchParams): string {
  let inviteCode: any = '';
  const tgWebAppStartParam = searchParams.get('tgWebAppStartParam') ||
                             searchParams.get('params');

  if (!tgWebAppStartParam) return;

  if (
    tgWebAppStartParam.includes('pina') ||
    tgWebAppStartParam.includes('banana')
  ) {
    inviteCode = extractNumbers(tgWebAppStartParam);
    return inviteCode;
  }

  if (tgWebAppStartParam.includes('p0xa')) {
    inviteCode = customDecode(tgWebAppStartParam)?.['ref'];
    return inviteCode;
  }
}

export function getEasterEggCode(searchParams: ReadonlyURLSearchParams): string {
  const tgWebAppStartParam = searchParams.get('tgWebAppStartParam') ||
                             searchParams.get('params');
  if (!tgWebAppStartParam) return;
  // if (tgWebAppStartParam.includes('p0xa')) {
  //   easterEggCode = customDecode(tgWebAppStartParam)?.['ref'];
  //   return easterEggCode;
  // }
  if (tgWebAppStartParam.startsWith('t')) {
    return tgWebAppStartParam.slice(1, tgWebAppStartParam.length);
  }
}
