import React, { useCallback, useEffect, useState } from 'react';
import { cn } from '@/lib/utils';
import { initUtils } from '@telegram-apps/sdk';
import { useUtils } from '@telegram-apps/sdk-react';
import Image from 'next/image';

type OpenADType = {
  className?: string;
};

export const OpenAD: React.FC<OpenADType> = ({ className }) => {
  const utils = useUtils();
  const [openAdExtend, setOpenAdExtend] = useState({
    key: 'openADSDK',
    APP: null,
    adInfo: {
      // zoneId: process.env.NEXT_PUBLIC_ZONE_ID,
      // reviveId: process.env.NEXT_PUBLIC_REVIVE_ID,
      zoneId: '56',
      reviveId: '6fced4eee9927b57847cf8dce447ceac',
    },
    img: {
      width: '',
      height: '',
      src: '',
      bannerid: '',
      campaignid: '',
      zoneid: '',
      loc: '',
      referer: '',
      cb: '',
      sig: '',
    },
  });

  useEffect(() => {
    const initOpenADSDK = async () => {
      const tryInit = async (retryCount = 0) => {
        try {
          const openAdSDK = window.openADSDK;

          if (openAdSDK) {
            const res = await openAdSDK.init(openAdExtend.adInfo);
            console.log('initOpenADSDK res', JSON.stringify(res));
            if (res.code === 0) {
              setOpenAdExtend((prev) => ({
                ...prev,
                APP: openAdSDK,
                img: res.data,
              }));
            }
          } else {
            if (retryCount < 5) {
              console.warn(
                `openADSDK not found, retrying in 200ms (attempt ${retryCount + 1})`,
              );
              setTimeout(() => tryInit(retryCount + 1), 200);
            } else {
              console.error('openADSDK not found after 5 attempts');
            }
          }
        } catch (error) {
          console.error('Error initializing OpenADSDK:', error);
        }
      };

      tryInit();
    };

    initOpenADSDK();
  }, [openAdExtend.adInfo]);

  const clickCb = useCallback(() => {
    const { img } = openAdExtend;
    const params = { bannerid: img.bannerid, zoneid: img.zoneid, sig: img.sig };
    openAdExtend.APP.click.cb(params, initUtils());
  }, [openAdExtend]);

  if (!openAdExtend.APP) return null;

  return (
    <div
      onClick={clickCb}
      className={cn("w-[80%] aspect-[468/60]", className)}>
      <Image
        src={openAdExtend.img.src}
        alt="openad"
        width={parseInt(openAdExtend.img.width || '0')}
        height={parseInt(openAdExtend.img.width || '0')}
        className="w-[100%] h-[100%] border-black border-solid border-[2px]"
      />
    </div>
  );
};
