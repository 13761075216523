import mitt from 'mitt';

export type Events = {
  userLogin: void;
  userLogout: void;
  autoLoginToTelegram: void;
  onLoginSuccess: void;
  onLogout: void;
  onUserInfoInitDone: void;
  toggleZendeskStatus: void;
  playSpinSound: void;
  playWinSound: void;
  playBetSound: void;
  playTimeUpSound: void;
  PlayBgSound: void;
  stopSpinSound: void;
  playCrashWinSound: void;
  playCrashBetSound: void;
  PlayCrashBgSound: void;
  noProgressBar: void;
  StopCrashBgSound: void;
  StopRushBgSound: void;
  delayBalanceUpdate: number;
};
const busEvents = mitt<Events>();
export default busEvents;
