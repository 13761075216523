import request from '@/http/request';
import { useQueries, useQuery } from '@tanstack/react-query';

export interface FriendRankList {
  tgId: string;
  name: string;
  registerIp: string;
  registerCountry: string;
  registerTime: string;
  gold: string;
  bananaCount: string;
  depositAmount: string;
}

export interface CityDTOItem {
  city: string;
  country: string;
  registerCountry: string;
  totalScore: string;
  countryRank: string;
  userList: UserDTOList[];
}

export interface AllRankItem {
  tgId: string;
  name: string;
  registerIp: string;
  registerCountry: string;
  registerTime: string;
  gold: string;
  bananaCount: string;
  depositAmount: string;
}

export interface PinaRewardList {
  userName: string;
  userHeadUrl: string;
  name: string;
  level: number;
  usdt: string;
}

export const fetchOverview = () => {
  return request<{
    overviewDTO: {
      playUserCount: string;
      playSuccessUserCount: string;
      friendRankList: FriendRankList[];
      cityDTOList: CityDTOItem[];
      allRankList: AllRankItem[];
      passes: number;
      challenges: number;
      challenger: number;
      achiever: number;
    };
  }>({
    url: '/api/mini-api/statistics/overview',
    data: {},
    method: 'post',
    suppressWarning: false,
  });
};

export interface UserDTOList {
  id: string;
  tgId: string;
  name: string;
  headerUrl: string;
  registerTime: string;
  gold: string;
  bananaCount: string;
  depositAmount: string;
}

export const fetchMyRanks = () => {
  return request<{
    myFriendRank: string;
    myCityRank: string;
    myGlobalRank: string;
    cityBananaCount: string;
    myBananaCount: string;
    friendRankings: UserDTOList[];
    cityUserRankingList: UserDTOList[];
    globalUserRankingList: UserDTOList[];
  }>({
    url: '/api/mini-api/statistics/my-ranks',
    data: {},
    method: 'post',
    suppressWarning: false,
  });
};

export const fetchCityUserList = (data: { city: string }) => {
  return request<{
    userDTOList: UserDTOList[];
  }>({
    url: '/api/mini-api/statistics/city-user-list',
    data: data,
    method: 'post',
    suppressWarning: false,
  }).then((res) => {
    return { ...res, city: data.city };
  });
};

export const useCityUserLists = (cities: string[]) => {
  return useQueries({
    queries: cities.map((city) => ({
      queryKey: ['cityUserList', city],
      queryFn: () => fetchCityUserList({ city }),
    })),
  });
};

export const useOverview = () => {
  return useQuery({
    queryKey: ['overview'],
    queryFn: () => fetchOverview(),
  });
};

export const useMyRanks = () => {
  return useQuery({
    queryKey: ['myRanks'],
    queryFn: () => fetchMyRanks(),
  });
};

// pina reward lists
export const fetchPinaReward = () => {
  return request<{
    list: PinaRewardList[];
  }>({
    url: '/api/mini-api/banana/record',
    data: {},
    method: 'post',
    suppressWarning: false,
  }).then((res) => {
    return res;
  });
};

export const usePinaReward = () => {
  return useQuery({
    queryKey: ['pinaReward'],
    queryFn: () => fetchPinaReward(),
  });
};

export interface RankListItem {
  name: string;
  logo: string;
  count: string;
  rank: string;
};

export interface RankInfo {
  rankType: string;
  list: RankListItem[];
  me: RankListItem;
};

export const fetchMyNewRank = () => {
  return request<{
    rankInfos: RankInfo[];
  }>({
    url: '/api/mini-api/statistics/my-new-ranks',
    data: {},
    method: 'post',
    suppressWarning: false,
  });
};

export const useMyNewRanks = () => {
  return useQuery({
    queryKey: ['myNewRanks'],
    queryFn: () => fetchMyNewRank().then(res => res.data),
  });
};
