'use client';
import { Button } from '@/components/ui/button';
import classNames from 'clsx';
import RouteLink, { LinkProps } from 'next/link';
import { usePathname, useParams, useRouter } from 'next/navigation';
import { endsWith, forEach, isString, startsWith } from 'lodash-es';
import React, { forwardRef, Ref } from 'react';
import { matchPath, PathPattern } from 'react-router';
import urlParse from 'url-parse';
import isInternalDomain from '@/utils/isInternalDomain';

export interface LocalizedLinkProps extends Omit<LinkProps, 'href'> {
  lang?: string;
  children?: React.ReactNode;
  className?: string;
  forwardRef?: Ref<any>;
  relOverride?: boolean;
  title?: string;
  pathPattern?: PathPattern | string;
  target?: React.HTMLAttributeAnchorTarget;
  style?: React.CSSProperties;
  isDisableLanguagePrefix?: boolean;
  forceRefresh?: boolean;
  disabled?: boolean;
  active?: boolean;
  href?: string;
  rel?: string;
  'data-qa-id'?: string;
}

const LocalizedLink = forwardRef<HTMLAnchorElement, LocalizedLinkProps>(
  (props: LocalizedLinkProps, ref) => {
    const pathname = usePathname();
    const { lng: locale } = useParams();

    const {
      lang = locale,
      children,
      className,
      onClick,
      relOverride,
      title,
      style,
      scroll = true,
      isDisableLanguagePrefix = false,
      prefetch = false,
      forceRefresh = false,
      shallow = true,
      pathPattern,
      active,
      disabled,
    } = props;

    let { href = '' } = props;
    const isActive =
      active || (href && !!matchPath(pathPattern || href, pathname));

    const usesLanguage = !isDisableLanguagePrefix;
    // !isDisableLanguagePrefix && startsWith('/', href);
    // !isDisableLanguagePrefix && startsWith('/', href) && lang !== 'en';

    if (usesLanguage) {
      href = `/${lang}${href}`;
    }

    let { target, rel = [] } = props;

    if (isString(rel)) {
      // @ts-ignore
      rel = [rel];
    }

    const parsedUrl = urlParse(href, true);
    const hostname = parsedUrl.hostname;

    if (!target) {
      if (hostname && !isInternalDomain(hostname)) {
        let isReferrerOk = false;

        // @ts-ignore
        forEach((domain) => {
          if (endsWith(domain as unknown as string, hostname)) {
            isReferrerOk = true;
            return false;
          }
          // }, configs?.links?.referrerOk || []);
        });

        // @ts-ignore
        rel.push('noopener nofollow');
        if (!isReferrerOk) {
          // @ts-ignore
          rel.push('noreferrer');
        }

        target = '_blank';
      }
    }

    const anchorProps = {
      ref,
      onClick,
      target,
      // rel={relOverride || rel.join(' ') || ''}
      prefetch,
      href,
      title,
      className: classNames(className),
      'data-qa-id': `${props['data-qa-id']}`,
    };

    if (disabled) {
      return <>{children}</>;
    }

    const ALink = (
      <a
        style={{ ...style, textDecoration: 'none' }}
        {...anchorProps}
        ref={ref}
      >
        {/* <Button className={classNames(className, { active: isActive })}> */}
          {children}
        {/* </Button> */}
      </a>
    );

    const allowDynamicLink = !forceRefresh && href && href[0] === '/';

    return allowDynamicLink ? (
      <RouteLink
        {...anchorProps}
        scroll={scroll}
        shallow={shallow}
        style={{ ...style, textDecoration: 'none' }}
        passHref
        ref={ref}
      >
        {/* <Button className={classNames(className, { active: isActive })}> */}
          {children}
        {/* </Button> */}
      </RouteLink>
    ) : (
      ALink
    );
  },
);

LocalizedLink.displayName = 'LocalizedLink';

export default LocalizedLink;
