import thinkingdata from 'thinkingdata-browser';

export const track = (eventName: string, propperties?: object) => {
  return thinkingdata.track(eventName, propperties);
};

export const pageView = (pageName: String) => {
  return thinkingdata.quick('autoTrack', {
    pageName,
  });
};
