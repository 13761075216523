import baseFetch, { JavaAPIResponse } from './fetch';
import { CustomAxiosRequestConfig } from './interceptors/types';
import configs from '@/configs';

export const baseURL = configs.env.endpoints.baseAPI;

const request = <T = any>(
  config: CustomAxiosRequestConfig,
): Promise<JavaAPIResponse<T>> => {
  return baseFetch({
    withAuthHeader: true,
    baseURL: `${baseURL}`,
    timeout: 10e3 * 10,
    ...config,
  }).then(({ data }) => data);
};

export default request;
