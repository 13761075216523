import { TIME_MAPS } from '@/constants/common';
import { isNumber, isString } from 'lodash-es';
import dayjs from 'dayjs';

export { TIME_MAPS } from '@/constants/common';
export * from './humanizedTime';

export const utcFunctions = {
  setHours: (isUTC) => (isUTC ? 'setUTCHours' : 'setHours'),
  getHours: (isUTC) => (isUTC ? 'getUTCHours' : 'getHours'),
  setDate: (isUTC) => (isUTC ? 'setUTCDate' : 'setDate'),
  getDate: (isUTC) => (isUTC ? 'getUTCDate' : 'getDate'),
  setMonth: (isUTC) => (isUTC ? 'setUTCMonth' : 'setMonth'),
  getMonth: (isUTC) => (isUTC ? 'getUTCMonth' : 'getMonth'),
  setFullYear: (isUTC) => (isUTC ? 'setUTCFullYear' : 'setFullYear'),
  getFullYear: (isUTC) => (isUTC ? 'getUTCFullYear' : 'getFullYear'),
};

export const addToDate: (
  isAdd: boolean,
  count: number,
  interval: TIME_MAPS,
  date?: Date | string | number,
  isUTC?: boolean,
) => Date = (
  isAdd = true,
  count = 0,
  interval = TIME_MAPS.YEAR,
  date,
  isUTC = false,
) => {
  if (!date) {
    date = new Date();
  } else if (isString(date) || isNumber(date)) {
    date = new Date(date);
  }

  if (!isAdd) {
    count *= -1;
  }

  if (interval === TIME_MAPS.DAY) {
    date[utcFunctions.setDate(isUTC)](
      date[utcFunctions.getDate(isUTC)]() + count,
    );
  } else if (interval === TIME_MAPS.MONTH) {
    date[utcFunctions.setMonth(isUTC)](
      date[utcFunctions.getMonth(isUTC)]() + count,
    );
  } else if (interval === TIME_MAPS.YEAR) {
    date[utcFunctions.setFullYear(isUTC)](
      date[utcFunctions.getFullYear(isUTC)]() + count,
    );
  } else {
    throw new Error('interval not supported');
  }

  return date as Date;
};

export const formatGameTime = (value: any) => {
  try {
    return dayjs(value).format('on MM/DD/YYYY [at] h:mm A');
  } catch (e) {
    return value;
  }
};

export const formatTransactionTime = (
  time: string | number,
  template = 'MM dd YYYY, hh:mm',
) => {
  if (!time) return '';
  try {
    const value = Number(time);
    if (value < 10e9) {
      return dayjs(value * 1000).format(template);
    } else if (value > 10e14) {
      return dayjs(value / 1000).format(template);
    } else {
      return dayjs(value).format(template);
    }
  } catch (e) {
    return time;
  }
};
